import React, { useEffect } from "react";
import { Backdrop, ModalWrapper } from "./styles";
import { isBrowser } from "@website-builder/utilities/utils/utils.js";

const Modal = ({
	disableBackdropClick = false,
	onClose = () => {},
	children,
	show = false,
	className = "",
}) => {
	useEffect(() => {
		if (isBrowser) {
			window.document.body.style.overflow = show ? "hidden" : "unset";
		}

		return () => {
			window.document.body.style.overflow = "unset";
		};
	}, [show]);

	const stopPropagation = (e) => e.stopPropagation();

	return (
		<>
			{show && (
				<Backdrop onClick={disableBackdropClick ? null : onClose}>
					<ModalWrapper
						show={show}
						onClick={stopPropagation}
						className={className}
					>
						<div className="closeButtonWrapper">
							<img
								src="https://res.cloudinary.com/springboard-images/image/upload/c_limit,dpr_1.0,f_svg,fl_lossy,q_auto/v1/homepage-assets/icons/dark-light-box-cross.svg"
								className="closeButton"
								alt="close modal"
								onClick={onClose}
							/>
						</div>

						{children}
					</ModalWrapper>
				</Backdrop>
			)}
		</>
	);
};

export default Modal;
