import styled from "styled-components";
import {
	COLOR_TRANSLUCENT_WHITE,
	COLOR_BORDER_GREY,
	COLOR_BOX_SHADOW,
	COLOR_WHITE,
} from "@website-builder/utilities/constants/style-constants/colors.js";

export const Backdrop = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 9999;
	left: 0;
	top: 0;
	background-color: ${COLOR_TRANSLUCENT_WHITE};
	overflow-y: auto;
`;

export const ModalWrapper = styled.div`
	display: inline-block;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 120px;
	background-color: ${COLOR_WHITE};
	border: 1px solid ${COLOR_BORDER_GREY};
	box-shadow: 0px 9px 20px ${COLOR_BOX_SHADOW};
	padding: 15px;
	border-radius: 3px;

	.closeButtonWrapper {
		text-align: right;
		position: absolute;
		right: 15px;
		top: 15px;

		.closeButton {
			cursor: pointer;
			width: 20px;
		}
	}
`;
